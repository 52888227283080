<template>
  <div class="">
    <div class="store-name">
      {{ props.storeName }}
    </div>

    <el-card>
      <div class="table-head-box">
        <el-button
          style="float: right"
          :icon="Refresh"
          size="small"
          round
          @click="getListData"
        ></el-button>
        <el-button class="button" size="small" @click="expandTable(true)">
          全部展开
        </el-button>
        <el-button class="button" size="small" @click="expandTable(false)">
          全部收起
        </el-button>
        <el-button
          v-permission="['addGoodsClass']"
          class="button"
          type="primary"
          size="small"
          @click="handleCreate"
        >
          新增
        </el-button>
        <el-button
          v-permission="['deleteGoodsClass']"
          class="button"
          type="danger"
          size="small"
          :disabled="!selectedIndexs.length"
          @click="handleAllDelete"
        >
          批量删除
        </el-button>
      </div>
      <el-table
        ref="multipleTable"
        :data="allClass"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        :default-expand-all="isExpand"
        :expand-row-keys="expandRowKeys"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" width="200"></el-table-column>
        <el-table-column label="所属分类" width="200" align="center">
          <template #default="{ row }">
            <el-tag
              v-if="row.xu !== null && row.xu !== undefined"
              :type="classLevels[row.xu].type"
              effect="dark"
            >
              {{ classLevels[row.xu].label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="classname"
          label="名称"
          min-width="200"
          align="center"
        ></el-table-column>

        <el-table-column
          label="操作"
          prop="action"
          width="180"
          align="center"
          #default="{ row }"
        >
          <el-button
            v-permission="['editGoodsClass']"
            type="primary"
            size="small"
            @click="handleUpdate(row)"
          >
            编辑
          </el-button>
          <el-button
            v-permission="['deleteGoodsClass']"
            type="danger"
            size="small"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </el-table-column>
      </el-table>
    </el-card>

    <create-dialog
      v-model="createDialogVisible"
      :info="editSelectClient"
      :storeId="props.storeId"
      @updateClient="getListData"
    ></create-dialog>
  </div>
</template>

<script setup>
import * as goodsClassApi from '@/api/goods-class.js'
import { ref, watch, onActivated, defineProps } from 'vue'
import { dataToTree } from '@/utils/tools.js'
import { ElMessageBox, ElMessage } from 'element-plus'
import { Refresh } from '@element-plus/icons-vue'
import CreateDialog from './components/create.vue'
import dayjs from 'dayjs'

const props = defineProps({
  storeId: {
    type: String,
    required: true
  },
  storeName: {
    type: String,
    default: ''
  }
})

// 数据相关
const multipleTable = ref(null)
const loading = ref(false)
/**
 * 权限分级：
 * 1. 一级权限为页面权限
 * 2. 二级权限为功能权限
 */
// 所有权限
const allClass = ref([])
const getListData = async () => {
  loading.value = true
  const list = await goodsClassApi.list({
    gysId: props.storeId,
    _t: dayjs().valueOf()
  })
  list.forEach(item => {
    item.name = item.classname
  })
  allClass.value = dataToTree(list, '', 'asc', '', 'pid')
  loading.value = false
}
// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

const selectedIndexs = ref([])
const handleSelectionChange = e => {
  selectedIndexs.value = e
}

// 新增点击事件
const createDialogVisible = ref(false)
const handleCreate = () => {
  createDialogVisible.value = true
}
// 修改点击事件
const editSelectClient = ref({})
const handleUpdate = row => {
  editSelectClient.value = row
  createDialogVisible.value = true
}

/**
 * 处理删除
 */
const handleDelete = row => {
  ElMessageBox.confirm('确定要删除 ' + row.classname + ' 吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    deleteFn([row.id])
  })
}
/**
 * 批量删除
 */
const handleAllDelete = () => {
  ElMessageBox.confirm('是否批量删除此信息吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    const ids = selectedIndexs.value.map(item => item.id)
    deleteFn(ids)
  })
}
/**
 * 处理删除请求
 */
const deleteFn = async ids => {
  try {
    await goodsClassApi.remove(ids)
    ElMessage.success('删除成功')
    // 重新渲染数据
    getListData()
    multipleTable.value.clearSelection()
  } catch (error) {}
}
watch(createDialogVisible, val => {
  if (!val) editSelectClient.value = {}
})

const isExpand = ref(false)
const expandRowKeys = ref([])
/**
 * 处理属性表格展开收起
 * @param {Boolean} isExpandAll 是否展开
 */
const expandTable = isExpandAll => {
  isExpand.value = isExpandAll
  // 展开所有行
  if (isExpand.value) {
    expandRowKeys.value = []
    allClass.value.forEach(row => {
      if (row.children && row.children.length > 0) {
        expandRowKeys.value.push(row.id)

        row.children.forEach(row2 => {
          if (row2.children && row2.children.length > 0) {
            expandRowKeys.value.push(row2.id)
          }
        })
      }
    })
  } else {
    //  折叠所有行
    expandRowKeys.value = []
  }
}

const classLevels = [
  {
    label: '大类',
    type: ''
  },
  {
    label: '中类',
    type: 'warning'
  },
  {
    label: '小类',
    type: 'info'
  }
]
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 22px;
}
.pagination {
  margin-top: 20px;
  text-align: center;
}
.table-head-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
  .button {
    margin: 0 5px;
  }
}
.store-name {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
</style>
