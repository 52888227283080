<template>
  <el-dialog
    :title="title"
    :model-value="modelValue"
    width="600px"
    destroy-on-close
    @close="closed"
  >
    <div class="news-create-container">
      <el-form
        ref="ruleFormRef"
        style="flex: 2"
        :model="form"
        label-width="120px"
        :rules="formRules"
      >
        <el-form-item label="分类名称" prop="classname" required>
          <el-input v-model="form.classname"></el-input>
        </el-form-item>

        <el-form-item label="分类" prop="xu" required>
          <el-select
            v-model="form.xu"
            placeholder="请选择"
            @change="classLevelsChange"
          >
            <el-option
              v-for="item in classLevels"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="form.xu === 1 || form.xu === 2"
          label="上级分类模块"
          prop="pid"
          required
        >
          <el-select
            v-model="form.pid"
            placeholder="请选择"
            filterable
            v-loading="classLoading"
          >
            <el-option
              v-for="item in classOptions"
              :key="item.id"
              :label="item.classname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <el-form :model="form" label-width="120px">
      <el-form-item>
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="onSubmit"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed } from 'vue'
import * as goodsClassApi from '@/api/goods-class.js'
import { ElMessage } from 'element-plus'
import _ from 'lodash'
import { rules } from '../data.js'

const props = defineProps({
  modelValue: {
    type: Boolean,
    require: true
  },
  info: {
    type: Object
  },
  storeId: {
    type: [String, Number],
    default: ''
  }
})

const emits = defineEmits(['update:modelValue', 'updateClient'])

// 判断是否有参数传递过来
// const isInfoFlag = computed(() => {
//   return JSON.stringify(props.info) === '{}'
// })

// 标题
const title = computed(() => {
  return `${JSON.stringify(props.info) === '{}' ? '创建' : '编辑'}分类`
})

// 数据源
const form = ref({
  classname: '',
  xu: 0,
  pid: 0
})

const ruleFormRef = ref(null)

// 取消
const closed = () => {
  form.value = {
    classname: '',
    xu: 0,
    pid: 0
  }

  emits('update:modelValue', false)
}

// 提交事件
const submitLoading = ref(false)
const onSubmit = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      submitLoading.value = true
      const param = _.cloneDeep({
        ...form.value,
        gysId: props.storeId
      })
      try {
        _.has(param, 'id')
          ? await goodsClassApi.update(param)
          : await goodsClassApi.add(param)
        ElMessage.success('更新成功')
        // emits('updateClient', param)
        emits('updateClient')
        closed()
      } finally {
        submitLoading.value = false
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 获取数据方法
const loading = ref(false)
const getDetail = async () => {
  loading.value = true
  try {
    const info = JSON.parse(JSON.stringify(props.info))
    form.value = info
    info.xu > 0 && getClassOptions(parseInt(info.xu) - 1)
    // console.log('form.value :>> ', form.value)
  } finally {
    loading.value = false
  }
}
watch(
  () => props.info,
  val => {
    if (JSON.stringify(val) !== '{}') getDetail()
  }
)

const classLoading = ref(false)
const classOptions = ref([])
const classLevelsChange = e => {
  if (e > 0) {
    form.value.pid = ''
    getClassOptions(parseInt(e) - 1)
    return
  }
  form.value.pid = 0
}
const getClassOptions = async (level = 0) => {
  classLoading.value = true
  try {
    const res = await goodsClassApi.list({
      gysId: props.storeId,
      level: level // 层级(0,1,2)
    })
    classLoading.value = false
    classOptions.value = res
  } catch (error) {
    classLoading.value = false
  }
}

const formRules = rules

const classLevels = [
  {
    label: '大类',
    value: 0,
    type: ''
  },
  {
    label: '中类',
    value: 1,
    type: 'warning'
  },
  {
    label: '小类',
    value: 2,
    type: 'info'
  }
]
</script>

<style lang="scss" scoped>
.news-create-container {
  display: flex;
  ::v-deep .el-form {
    flex: 1;
  }
}
</style>
