import request from '@/utils/request'

const baseUrl = '/manager/warehouseClass'

/**
 * 获取列表
 */
export const list = data => {
  return request({
    url: baseUrl,
    params: data
  })
}

/**
 * 创建
 */
export const add = data => {
  return request({
    url: baseUrl,
    method: 'POST',
    data
  })
}

/**
 * 更新
 */
export const update = data => {
  return request({
    url: baseUrl,
    method: 'PUT',
    data
  })
}

/**
 * 删除
 */
export const remove = id => {
  return request({
    url: `${baseUrl}/${id}`,
    method: 'DELETE'
  })
}
