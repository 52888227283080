export const rules = {
  /* name: [
    { required: true, message: 'Please input Activity name', trigger: 'blur' },
    { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
  ], */
  classname: [
    {
      required: true,
      message: '请输入分类名称',
      trigger: 'blur'
    }
  ],
  xu: [
    {
      required: true,
      message: '请选择分类',
      trigger: 'blur'
    }
  ],
  pid: [
    {
      required: true,
      message: '请选择上级分类模块',
      trigger: 'blur'
    }
  ]
}
